<mat-accordion [displayMode]="displayMode">
  <mat-expansion-panel hideToggle class="m-b-md" [class.offset-field]="offsetField">
    <mat-expansion-panel-header>
      <mat-panel-description class="flex-justify-start text-size-small">
        <mat-icon class="m-r-xs">info</mat-icon>
        {{ title }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
