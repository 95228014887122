import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EMAIL_PATTERN, WEB_URL_PATTERN } from './regular-expression';

import { ErrorTailorConfig } from '@ngneat/error-tailor';
import { REGEX_YEAR } from '../app.constant';
import { TranslocoService } from '@ngneat/transloco';
import { HOME_MAX_BLOCKS } from '../modules/home/home.config';

export const COLLECTION_DOMAIN_REGEX = /^(?!-)[a-zA-Z0-9-]*[^-]$/; // With no lenght verification
export const COLLECTION_DOMAIN_REGEX_WITH_LENGTH = /^(?!-)[a-zA-Z0-9-]{4,20}(?<!-)$/;

export const PASSWORD_REGEX = /^[0-9a-zA-Z!@#$%^&*<>:$*+?]*$/; // With no lenght verification
export const PASSWORD_REGEX_WITH_LENGTH = /^[0-9a-zA-Z!@#$%^&*<>:$*+?]{6,}$/;

const OPTIONS_MIN = 3;
const OPTIONS_MAX = 32;

export const FORM_VALIDATORS = {
  password: [Validators.minLength(8), Validators.maxLength(20), Validators.pattern(PASSWORD_REGEX)],
  phone: [Validators.minLength(8), Validators.maxLength(20)],

  fileName: [Validators.minLength(2), Validators.maxLength(32)],

  name: [Validators.minLength(2), Validators.maxLength(32)],
  longName: [Validators.minLength(2), Validators.maxLength(64)],
  dbTitle: [Validators.minLength(4), Validators.maxLength(64)],

  address: [Validators.minLength(2), Validators.maxLength(32)],
  city: [Validators.minLength(2), Validators.maxLength(32)],

  rank: [Validators.min(1), Validators.max(HOME_MAX_BLOCKS)],
  dimensions: [Validators.min(1), Validators.max(9999)],

  price: [Validators.min(1), Validators.max(9999999999)],

  longTitle: [Validators.minLength(4), Validators.maxLength(128)],

  shortText: [Validators.minLength(2), Validators.maxLength(10)],
  mediumText: [Validators.minLength(2), Validators.maxLength(20)],
  longText: [Validators.minLength(2), Validators.maxLength(32)],

  textArea: [Validators.minLength(8), Validators.maxLength(2048)],

  email: [Validators.pattern(EMAIL_PATTERN), Validators.minLength(8), Validators.maxLength(64)],
  website: [Validators.pattern(WEB_URL_PATTERN), Validators.minLength(8), Validators.maxLength(64)],
  year: [Validators.maxLength(4), Validators.minLength(4), Validators.pattern(REGEX_YEAR)],

  // Work select list
  type: [Validators.minLength(OPTIONS_MIN), Validators.maxLength(OPTIONS_MAX)],
  currency: [Validators.minLength(OPTIONS_MIN), Validators.maxLength(OPTIONS_MAX)],
  country: [Validators.minLength(OPTIONS_MIN), Validators.maxLength(OPTIONS_MAX)],
  nationality: [Validators.minLength(OPTIONS_MIN), Validators.maxLength(OPTIONS_MAX)],
  location: [Validators.minLength(OPTIONS_MIN), Validators.maxLength(OPTIONS_MAX)],
};

// Validators translations
export const ValidatorErrorConfig: ErrorTailorConfig = {
  errors: {
    useFactory(service: TranslocoService) {
      return {
        required: () => service.translate('validator.fieldMissing'),
        minlength: (config: { requiredLength: number; actualLength: number }) =>
          service.translate('validator.tooShortInput', {
            requiredLength: config.requiredLength,
            actualLength: config.actualLength,
          }),
        maxlength: (config: { requiredLength: number; actualLength: number }) =>
          service.translate('validator.tooLongInput', {
            requiredLength: config.requiredLength,
            actualLength: config.actualLength,
          }),
        min: (config: { min: number; actual: number }) =>
          service.translate('validator.min', {
            min: config.min,
            actual: config.actual,
          }),
        max: (config: { max: number; actual: number }) =>
          service.translate('validator.max', {
            max: config.max,
            actual: config.actual,
          }),
        pattern: (config: { requiredPattern: string | RegExp }) => {
          if (config.requiredPattern === PASSWORD_REGEX) {
            return service.translate('validator.invalidPassword');
          }
          if (config.requiredPattern === EMAIL_PATTERN) {
            return service.translate('validator.invalidEmail');
          }
          if (config.requiredPattern === WEB_URL_PATTERN) {
            return service.translate('validator.invalidWebUrl');
          }
          if (config.requiredPattern === REGEX_YEAR) {
            return service.translate('validator.invalidYear');
          }
          return service.translate('validator.invalidPattern');
        },
      };
    },
    deps: [TranslocoService],
  },
  blurPredicate(element) {
    return (
      element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA'
    );
  },
  // controlErrorsOn: {
  //   async: true,  // (default: true)
  //   blur: true,   // (default: true)
  //   change: true, // (default: false)
  // }
};

export function customValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}
