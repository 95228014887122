import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LangSwitcherComponent } from './lang-switcher/lang-switcher.component';
import { LoaderComponent } from './loader/loader.component';
import { LogoComponent } from './logo/logo.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ThemeDemoComponent } from './theme-demo/theme-demo.component';
import { TranslocoModule } from '@ngneat/transloco';
import { UiModule } from '../shared/ui/ui.module';
import { UserMenuComponent } from './user-menu/user-menu.component';
@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    ThemeDemoComponent,
    FooterComponent,
    LogoComponent,
    LangSwitcherComponent,
    UserMenuComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    RouterModule,

    // Material
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatRippleModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,

    TranslocoModule,

    // Custom
    UiModule,
  ],
  exports: [LoaderComponent, HeaderComponent, FooterComponent, LogoComponent],
})
export class ThemeModule {}
