<div class="flex-center flex-wrap m-xs">
  <button
    mat-button
    *ngIf="showCreate"
    (click)="onCreateClicked($event)"
    [matTooltip]="createTooltip"
    [disabled]="createDisabled"
    matTooltipPosition="above"
    [ngClass]="{ active: isEditActive }"
  >
    {{ createLabel }}
  </button>
  <button
    mat-button
    *ngIf="showEdit"
    (click)="onEditClicked($event)"
    [disabled]="editDisabled"
    [matTooltip]="editTooltip"
    matTooltipPosition="above"
  >
    {{ editLabel }}
  </button>
  <button mat-button *ngIf="showInfo" [matTooltip]="infoTooltip" matTooltipPosition="above">
    <mat-icon>info</mat-icon>
  </button>
</div>
