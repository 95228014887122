import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatAccordionDisplayMode } from '@angular/material/expansion';

@Component({
  selector: 'arsultima-info-helper',
  templateUrl: './info-helper.component.html',
  styleUrls: ['./info-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoHelperComponent {
  displayMode: MatAccordionDisplayMode = 'flat';

  @Input()
  title = '';

  @Input()
  offsetField = false;
}
